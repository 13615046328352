<template>
  <div>
    <a :href="requestLink()" v-if="count > 0" target="_blank">{{ text }} ({{ count }})</a>
    <span v-else>{{ text }} ({{ count }})</span>
  </div>
</template>

<script>
export default {
  name: "OrderLink",
  props: {
    count: {
      type: Number,
      default: () => 0
    },
    text: {
      type: String,
      default: () => ''
    },
    tracking_id: {
      type: String,
      default: () => ''
    },
    status: {
      type: Number,
      default: () => 0
    },
  },
  methods: {
    requestLink() {
      return `/v2/internal-request?order_tracking_id=${this.tracking_id}&status=${this.status}`
    }
  },

}
</script>

<style scoped>

</style>
